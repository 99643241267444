import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import StyledHeaderImage from '../components/header-image';

const BlueBackground = styled.div`
  background-color: #346993;
`;

const Container = styled.div`
  width: 60%;
  text-align: center;
  margin: auto;
  max-width: 960px;
  padding: 4rem 0;
  color: white;

  @media (max-width: 750px) {
    width: 80%;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 2rem;

  @media (max-width: 800px) {
    display: block;
  }
`;

const Icon = styled.img`
  padding: 0 1rem;
  width: 32px;
`;

const IconWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease;
  cursor: pointer;
  color: unset;
  text-decoration: none;

  &:hover * {
    filter: invert(0.2);
  }
`;

const Form = styled.form`
  padding: 1rem 0;
  max-width: 600px;
  margin: auto;
`;

const TextField = styled.input`
  width: 100%;
  margin-top: 1rem;
  padding: 0.8rem;
  background-color: #ccc;
  border: 1px solid #aaa;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: sans-serif;
  font-size: 0.9em;
`;

const CommentsField = styled.textarea`
  width: 100%;
  margin-top: 1rem;
  padding: 0.8rem;
  background-color: #ccc;
  border: 1px solid #aaa;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: sans-serif;
  font-size: 0.9em;
  resize: vertical;
  margin-top: 1rem;
  height: 100px;
`;

const Submit = styled.input`
  background-color: #88ab75;
  border: none;
  border-radius: 3px;
  color: white;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  font-family: 'Port Lligat Slab', 'serif';
  font-size: 1.25em;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    background-color: #7c9c6b;
    transform: translateY(5%) scale(0.99);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  }
`;

const ContactPage = () => {
  return (
    <Layout>
      <StyledHeaderImage page="contact" text="Contact Us" />
      <BlueBackground>
        <Container>
          <h1>Ready to make an exchange?</h1>
          <h1>We're here to help!</h1>
          <ContactWrapper>
            <IconWrapper href="tel:7277875290">
              <Icon src={require('../images/phone.svg')} alt="phone icon" />
              <h3>(727) 787-5290</h3>
            </IconWrapper>
            <IconWrapper href="mailto:info@1031agency.com">
              <Icon src={require('../images/email.svg')} alt="email icon" />
              <h3>info@1031agency.com</h3>
            </IconWrapper>
          </ContactWrapper>
          <Form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <TextField type="text" id="name" name="name" placeholder="name" />
            <TextField
              type="email"
              id="email"
              name="email"
              placeholder="email"
            />
            <TextField type="tel" id="phone" name="phone" placeholder="phone" />
            <CommentsField
              id="comments"
              name="comments"
              placeholder="comments"
            />
            <Submit type="submit" value="Send" />
          </Form>
        </Container>
      </BlueBackground>
    </Layout>
  );
};

export default ContactPage;
